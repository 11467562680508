import { type WPPluginFunction } from '../../WPPlugins';

import dataVisScrollListener from './helpers/datavisScrollListener';

/**
 * Initialises the DataVisScrollListener utility on the HBC data visualisation.
 * @returns {WPPlugin} - The WordPress Plugin
 */
const DatavisHbcPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    dataVisScrollListener(root, {
      target: '.datavis-hbc li:first-child .datavis-hbc__location',
      wrapper: '.datavis-hbc',
    });
  },
});

export default DatavisHbcPlugin;
