import type { AosElement } from '../types';

/**
 * Generate initial array with elements as objects
 * This array will be extended later with elements attributes values
 * like 'position'
 * @param {ShadowRoot} root - root element
 * @returns {array} array with elements
 */
export default (root: ShadowRoot): AosElement[] => {
  const elements = root.querySelectorAll<HTMLElement>('[data-aos]');

  return Array.prototype.map.call<NodeListOf<HTMLElement>, any, AosElement[]>(
    elements,
    (node: HTMLElement) => ({ node }),
  );
};
