/* eslint-disable no-param-reassign */

import getInlineOption from './getInlineOption';
import { getPositionIn, getPositionOut } from './offsetCalculator';

import type { AosElement, AosOptions } from '../types';

const prepare = ($elements: AosElement[], options: AosOptions) => {
  $elements.forEach((el, i) => {
    const mirror = getInlineOption(el.node, 'mirror', String(options.mirror));
    const once = getInlineOption(el.node, 'once', String(options.once));
    const id = getInlineOption(el.node, 'id');
    const customClassNames =
      options.useClassNames && el.node.getAttribute('data-aos');

    const animatedClassNames = [options.animatedClassName]
      .concat(customClassNames ? customClassNames.split(' ') : [])
      .filter((className) => typeof className === 'string');

    if (options.initClassName) {
      el.node.classList.add(options.initClassName);
    }

    el.position = {
      in: getPositionIn(
        el.node,
        options.offset as number,
        options.anchorPlacement as string,
      ),
      out: (mirror && getPositionOut(el.node, options.offset as number)) || 0,
    };

    el.options = {
      once: !!once,
      mirror: !!mirror,
      animatedClassNames,
      id: id as string,
    };
  });

  return $elements;
};

export default prepare;
