import { type WPPluginFunction } from '../WPPlugins';

/**
 * Open an accordion item.
 *
 * @param {HTMLElement} item Item wrapper element.
 * @returns {void}
 */
const openItem = (item: HTMLElement) => {
  item.classList.add('bpk-accordion--open');

  const content = item.querySelector<HTMLElement>(
    '.bpk-accordion__content-container',
  );

  if (content) {
    const height = content.scrollHeight;
    content.style.height = `${height}px`;
  }
};

/**
 * Close an accordion item.
 *
 * @param {HTMLElement} item Item wrapper element.
 * @returns {void}
 */
const closeItem = (item: HTMLElement) => {
  item.classList.remove('bpk-accordion--open');

  const content = item.querySelector<HTMLElement>(
    '.bpk-accordion__content-container',
  );

  if (content) {
    content.style.height = '0';
  }
};

/**
 * Find an ancestor element with a particular class.
 *
 * @param {HTMLButtonElement} element Element to start search at.
 * @param {String} selector Class name to search for.
 * @returns {HTMLElement} Ancestor element.
 */
const findAncestor = (element: HTMLButtonElement, selector: string) => {
  let currentElement: HTMLElement | null = element;

  while (
    // eslint-disable-next-line no-cond-assign
    (currentElement = currentElement.parentElement) &&
    !currentElement.classList.contains(selector)
  );

  return currentElement;
};

/**
 * Toggle an accordion item open or closed.
 *
 * @param {MouseEvent} e Click event.
 * @returns {void}
 */
const toggleAccordionItem = (e: MouseEvent) => {
  e.preventDefault();

  const button = e.target || e.srcElement;

  const parent = findAncestor(
    button as HTMLButtonElement,
    'bpk-accordion__item',
  );

  if (!parent) {
    return;
  }

  if (parent?.classList.contains('bpk-accordion--open')) {
    closeItem(parent);
  } else {
    openItem(parent);
  }
};

/**
 * Add click handlers to accordion item titles.
 *
 * @param {HTMLElement} item Accordion item.
 * @returns {void}
 */
const addHandlers = (item: HTMLElement) => {
  const button = item.querySelector<HTMLButtonElement>(
    '.bpk-accordion__toggle-button',
  );

  button?.addEventListener('click', toggleAccordionItem);
};

/**
 * Accordion Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const AccordionPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const accordionItems = root.querySelectorAll<HTMLElement>(
      '.bpk-accordion__item',
    );

    // Hook in click events to each item.
    accordionItems.forEach((item) => addHandlers(item));
  },
});

export default AccordionPlugin;
