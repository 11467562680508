/**
 * Get inline option with a fallback.
 *
 * @param  {Node} el [Dom element]
 * @param  {String} key [Option key]
 * @param  {String} fallback [Default (fallback) value]
 * @return {Mixed} [Option set with inline attributes or fallback value if not set]
 */

export default (el: HTMLElement, key: string, fallback?: string) => {
  const attr = el.getAttribute(`data-aos-${key}`);

  if (typeof attr !== 'undefined') {
    if (attr === 'true') {
      return true;
    }

    if (attr === 'false') {
      return false;
    }
  }

  return attr ?? fallback;
};
