import { type WPPluginFunction } from '../WPPlugins';

const initAudioPlayer = (containerEl: HTMLElement) => {
  const audio = containerEl.querySelector<HTMLAudioElement>(
    '.skyscanner-audio-player__audio',
  );

  if (!audio) {
    return;
  }

  let isPlaying = false;

  const playPauseButton = containerEl.querySelector<HTMLButtonElement>(
    '.skyscanner-audio-player__play-pause-button',
  );
  const progressBarEl = containerEl.querySelector<HTMLDivElement>(
    '.skyscanner-audio-player__progress-bar',
  );
  const currentProgressIndicatorEl = containerEl.querySelector<HTMLDivElement>(
    '.skyscanner-audio-player__current-progress-indicator',
  );
  const durationEl = containerEl.querySelector<HTMLDivElement>(
    '.skyscanner-audio-player__duration',
  );

  // Function to update seek position when dragging
  const updateSeek = (event: MouseEvent) => {
    if (!progressBarEl) {
      return;
    }

    const progressBarElRect = progressBarEl.getBoundingClientRect();
    const mouseX = event.clientX - progressBarElRect.left;
    const progress = mouseX / progressBarElRect.width;
    const seekTime = audio.duration * progress;
    audio.currentTime = seekTime;
  };

  // Function to format time as "X minutes and Y seconds"
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    const minutesString = minutes > 0 ? `${minutes} min` : '';
    const secondsString = seconds > 0 ? `${seconds} sec` : '';

    if (minutesString && secondsString) {
      return `${minutesString} and ${secondsString}`;
    }

    return minutesString || secondsString;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    isPlaying = !isPlaying;
  };

  playPauseButton?.addEventListener('click', handlePlayPause);

  audio.addEventListener('play', () => {
    containerEl.classList.add('skyscanner-audio-player--is-playing');
  });

  audio.addEventListener('pause', () => {
    containerEl.classList.remove('skyscanner-audio-player--is-playing');
  });

  audio.addEventListener('timeupdate', () => {
    const progress = (audio.currentTime / audio.duration) * 100;

    if (currentProgressIndicatorEl) {
      currentProgressIndicatorEl.style.width = `${progress}%`;
    }
  });

  if (audio.duration) {
    const totalDuration = formatTime(audio.duration);

    if (durationEl) {
      durationEl.textContent = totalDuration;
    }
  }

  audio.addEventListener('durationchange', () => {
    const totalDuration = formatTime(audio.duration);

    if (durationEl) {
      durationEl.textContent = totalDuration;
    }
  });

  audio.addEventListener('ended', () => {
    audio.currentTime = 0;

    if (currentProgressIndicatorEl) {
      currentProgressIndicatorEl.style.width = '0%';
    }

    if (playPauseButton) {
      playPauseButton.classList.remove('pause');
    }
    isPlaying = false;
  });

  progressBarEl?.addEventListener('mouseup', (event) => {
    updateSeek(event);
  });
};

/**
 * Audio Player Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const AudioPlayerPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    root
      .querySelectorAll<HTMLElement>('.skyscanner-audio-player')
      .forEach((el) => initAudioPlayer(el));
  },
});

export default AudioPlayerPlugin;
