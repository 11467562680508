import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../WPPlugins';

const loadShowcase = (container: HTMLDivElement) => {
  const $window = GLOBAL.getWindow();

  const items = container.querySelectorAll<HTMLDivElement>('.showcases__item');
  let desktopContentText: HTMLDivElement | null = null;
  let isHeadingsAppended = false;

  // Function to rearrange items for desktop view
  function rearrangeItems() {
    const isDesktop = $window.matchMedia('(min-width: 769px)').matches;

    // Separate items based on the viewport size
    const desktopLeftColumnItems: HTMLDivElement[] = [];
    const desktopRightColumnItems: HTMLDivElement[] = [];
    const mobileItems: HTMLDivElement[] = [];

    items.forEach((item, index) => {
      if (isDesktop) {
        // For the first item, move .showcases__content to the second item
        if (index === 0) {
          const contentText = item.querySelector<HTMLDivElement>(
            '.showcases__content',
          );
          if (contentText) {
            desktopContentText = contentText;
            contentText.classList.add('showcases__content--left-align');
            const secondItem = items[1];
            if (secondItem) {
              // Prepend the .showcases__content element as the first child of the second item
              secondItem.prepend(contentText);
            }
          }

          // Append the .showcases__headings element in the end of the first item
          const { parentElement } = container;
          const headingsElement = parentElement?.querySelector<HTMLDivElement>(
            '.showcases__headings',
          );
          if (headingsElement && !isHeadingsAppended) {
            item.appendChild(headingsElement); // Append .showcases__headings to the first item
            isHeadingsAppended = true; // Mark headings as appended
          }
        }

        if (index % 2 === 0) {
          desktopLeftColumnItems.push(item);
        } else {
          desktopRightColumnItems.push(item);
        }
      } else {
        mobileItems.push(item);
      }
    });

    // Clear the container
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    // Append items based on the viewport size and column
    if (isDesktop) {
      desktopLeftColumnItems.forEach((item) => {
        item.classList.add('showcases__item--left');
        container.appendChild(item);
      });
      desktopRightColumnItems.forEach((item) => {
        item.classList.add('showcases__item--right');
        container.appendChild(item);
      });
    } else {
      mobileItems.forEach((item) => {
        // If there is a .showcases__content element from desktop, append it back to the first item
        if (desktopContentText) {
          const firstItem = items[0];
          if (firstItem) {
            firstItem.appendChild(desktopContentText);
            desktopContentText = null; // Reset the reference since it's been appended back
          }
        }

        // Move the .showcases__headings element back to its original position
        const headingsElement = container.querySelector('.showcases__headings');
        if (headingsElement && isHeadingsAppended) {
          const { parentElement } = container;
          parentElement?.prepend(headingsElement);
          isHeadingsAppended = false; // Reset the flag after appending
        }

        container.appendChild(item);
      });
    }

    // Add class .showcases__blocks--two if there are only two items
    if (items.length === 2) {
      container.classList.add('showcases__blocks--two');
    } else {
      container.classList.remove('showcases__blocks--two');
    }
  }

  // Initial rearrangement on page load
  rearrangeItems();

  // Re-run rearrangement when the window is resized
  $window.addEventListener('resize', () => {
    rearrangeItems();
  });
};

/**
 * Showcases Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const ShowcasesPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const containers =
      root.querySelectorAll<HTMLDivElement>('.showcases__blocks');

    containers.forEach((container) => {
      loadShowcase(container);
    });
  },
});

export default ShowcasesPlugin;
