type DatavisSettings = {
  target: string;
  threshold?: number;
  wrapper: string;
};

/**
 * Animate slice
 * @param {ShadowRoot} root - The root element
 * @returns {void}
 */
const animatePies = (root: ShadowRoot) => {
  const pies = root.querySelectorAll<HTMLDivElement>('.datavis-pie-chart');

  const getPercentage = (p: HTMLDivElement) =>
    getComputedStyle(p).getPropertyValue('--percentage');

  pies.forEach((pie) => {
    const stat = parseInt(getPercentage(pie), 10);
    const speed = Math.min(600 / stat, 15); // 15ms is slow but still smooth transition.

    pie.style.setProperty('--percentage', '0');

    new MutationObserver((mutations, observer) => {
      for (const mutation of mutations) {
        if (
          mutation.attributeName === 'class' &&
          pie.classList.contains('animation-play')
        ) {
          setTimeout(animate, 500);
          observer.disconnect();
        }
      }
    }).observe(pie, { attributes: true });

    const animate = () => {
      const current = parseInt(getPercentage(pie), 10);

      if (current < stat) {
        pie.style.setProperty('--percentage', String(current + 1));
        setTimeout(animate, speed);
      }
    };
  });
};

const scrollListener = (root: ShadowRoot, settings: DatavisSettings) => {
  const { target, threshold = 0.2, wrapper } = settings;

  const observers = [];

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold,
  };

  const charts = root.querySelectorAll(target);

  for (let i = 0; i < charts.length; i += 1) {
    observers[i] = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start the animation.
          entry.target.closest(wrapper)?.classList.add('animation-play');
          // Stop watching.
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
    observers[i].observe(charts[i]);
  }
};

const dataVisScrollListener = (root: ShadowRoot, settings: DatavisSettings) => {
  root
    .querySelectorAll(settings.wrapper)
    .forEach((e) => e.classList.add('animation-ready'));

  scrollListener(root, settings);
  animatePies(root);
};

export default dataVisScrollListener;
