import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../../WPPlugins';

import detect from './helpers/detector';
import elements from './helpers/elements';
import handleScroll from './helpers/handleScroll';
import prepare from './helpers/prepare';
import observer from './libs/observer';

import type { AosElement, AosOptions } from './types';

const options: AosOptions = {
  offset: 120,
  delay: 0,
  easing: 'ease',
  duration: 400,
  disable: false,
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
  startEvent: 'DOMContentLoaded',
  animatedClassName: 'aos-animate',
  initClassName: 'aos-init',
  useClassNames: false,
  disableMutationObserver: false,
  throttleDelay: 99,
  debounceDelay: 50,
};

/**
 * Private variables
 */
let $aosElements: AosElement[];
let initialized = false;

// Detect not supported browsers (<=IE9)
// http://browserhacks.com/#hack-e71d8692f65334173fee715c222cb805
const isBrowserNotSupported = () => {
  const $window = GLOBAL.getWindow();
  const $document = GLOBAL.getDocument();

  return $document.all && !$window.atob;
};

const initializeScroll = function initializeScroll() {
  const $window = GLOBAL.getWindow();

  // Extend elements objects in $aosElements with their positions
  $aosElements = prepare($aosElements, options);
  // Perform scroll event, to refresh view and show/hide elements
  handleScroll($aosElements);

  /**
   * Handle scroll event to animate elements on scroll
   */
  $window.addEventListener(
    'scroll',
    throttle(() => {
      handleScroll($aosElements);
    }, options.throttleDelay),
  );

  return $aosElements;
};

/**
 * Refresh AOS
 * @param {boolean} initialize - flag to set initialization flag
 * @returns {void}
 */
const refresh = function refresh(initialize = false) {
  // Allow refresh only when it was first initialized on startEvent
  if (initialize) initialized = true;
  if (initialized) initializeScroll();
};

/**
 * Hard refresh
 * create array with new elements and trigger refresh
 * @param {ShadowRoot} root - root element
 * @returns {void}
 */
// eslint-disable-next-line consistent-return
const refreshHard = (root: ShadowRoot) => {
  $aosElements = elements(root);

  if (isDisabled(options.disable) || isBrowserNotSupported()) {
    return disable();
  }

  refresh();
};

/**
 * Disable AOS
 * Remove all attributes to reset applied styles
 * @returns {void}
 */
const disable = () => {
  $aosElements.forEach((el, i) => {
    el.node.removeAttribute('data-aos');
    el.node.removeAttribute('data-aos-easing');
    el.node.removeAttribute('data-aos-duration');
    el.node.removeAttribute('data-aos-delay');

    if (options.initClassName) {
      el.node.classList.remove(options.initClassName);
    }

    if (options.animatedClassName) {
      el.node.classList.remove(options.animatedClassName);
    }
  });
};

/**
 * Check if AOS should be disabled based on provided setting
 * @param {any} optionDisable - provided plugin setting
 * @returns {boolean} - true if AOS should be disabled
 */
const isDisabled = (optionDisable: AosOptions['disable']) =>
  optionDisable === true ||
  (optionDisable === 'mobile' && detect.mobile()) ||
  (optionDisable === 'phone' && detect.phone()) ||
  (optionDisable === 'tablet' && detect.tablet()) ||
  (typeof optionDisable === 'function' && optionDisable() === true);

/**
 * Initialises the Animation on Scroll utility.
 * @returns {WPPlugin} - The WordPress Plugin
 */
const DatavisHbcPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const $window = GLOBAL.getWindow();
    const $document = GLOBAL.getDocument();

    // Create initial array with elements -> to be fullfilled later with prepare()
    $aosElements = elements(root);

    /**
     * Disable mutation observing if not supported
     */
    if (!options.disableMutationObserver && !observer.isSupported()) {
      options.disableMutationObserver = true;
    }

    /**
     * Observe [aos] elements
     * If something is loaded by AJAX
     * it'll refresh plugin automatically
     */
    if (!options.disableMutationObserver) {
      observer.ready('[data-aos]', () => refreshHard(root));
    }

    /**
     * Don't init plugin if option `disable` is set
     * or when browser is not supported
     */
    if (isDisabled(options.disable) || isBrowserNotSupported()) {
      disable();
    } else {
      root
        .querySelector('.tt-aos')
        ?.setAttribute('data-aos-easing', options.easing as string);

      root
        .querySelector('.tt-aos')
        ?.setAttribute('data-aos-duration', String(options.duration));

      root
        .querySelector('.tt-aos')
        ?.setAttribute('data-aos-delay', String(options.delay));

      /**
       * Handle initializing
       */
      if (
        ['DOMContentLoaded', 'load'].indexOf(options.startEvent as string) ===
        -1
      ) {
        // Listen to options.startEvent and initialize AOS
        $document.addEventListener(options.startEvent as string, () => {
          refresh(true);
        });
      } else {
        $window.addEventListener('load', () => {
          refresh(true);
        });
      }

      if (
        options.startEvent === 'DOMContentLoaded' &&
        ['complete', 'interactive'].indexOf($document.readyState) > -1
      ) {
        // Initialize AOS if default startEvent was already fired
        refresh(true);
      }

      /**
       * Refresh plugin on window resize or orientation change
       */
      $window.addEventListener(
        'resize',
        debounce(() => refresh(), options.debounceDelay as number),
      );

      $window.addEventListener(
        'orientationchange',
        debounce(() => refresh(), options.debounceDelay as number),
      );
    }
  },
});

export default DatavisHbcPlugin;
