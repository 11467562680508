import fitty from 'fitty';

import type { WPPluginFunction } from '../WPPlugins';

/**
 * Fitty Card Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const FittyCardPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const container = root.querySelector<HTMLDivElement>('.fitty-card__title');

    if (!container) {
      return;
    }

    fitty(container, {
      minSize: 40,
      maxSize: 200,
    });

    root.querySelector('#body')?.classList.add('fitty-initialized');
  },
});

export default FittyCardPlugin;
