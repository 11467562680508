/* eslint-disable react/no-danger */
import { useEffect, useRef } from 'react';

import classNames from 'classnames';
import root from 'react-shadow';

import WPPlugins from './plugins/WPPlugins';
import * as plugins from './plugins/library';

import type { WordPressArticleProps } from '@skyscanner-internal/falcon-shared-types/types/WordPressArticleProps';

import STYLES from './WordPressArticle.module.scss';

const templateToClassesMap = {
  single: {
    content: 'wrap content',
    body: 'post-template-default single single-post single-format-standard wp-embed-responsive',
  },
  'full-width': {
    content: 'wrap content full-width',
    body: 'page-template page-template-page-templates page-template-full-width page-template-page-templatesfull-width-php page page-child wp-embed-responsive',
  },
  'narrow-width': {
    content: 'wrap content narrow-width',
    body: 'page-template page-template-page-templates page-template-narrow-width page-template-page-templatesnarrow-width-php page page-child wp-embed-responsive',
  },
  'travel-trends': {
    content: 'content travel-trends',
    body: 'page-template page-template-page-templates page-template-travel-trends page-template-page-templatestravel-trends-php page wp-embed-responsive tt-aos',
  },
};

const WordPressArticle = ({
  bodyStyles,
  htmlContent,
  ssr = true,
  template,
}: WordPressArticleProps) => {
  const shadowHostRef = useRef<HTMLDivElement>(null);
  const isTravelTrends = template === 'travel-trends';

  const articleClassNames = classNames('entry-content', {
    'is-layout-constrained': isTravelTrends,
  });

  const bodyClassNames = templateToClassesMap[template]
    ? templateToClassesMap[template].body
    : templateToClassesMap['full-width'].body;

  const contentClassNames = templateToClassesMap[template]
    ? templateToClassesMap[template].content
    : templateToClassesMap['full-width'].content;

  // Shadow host is a declarative shadow dom. If the root wasn't created (meaning that declarative mode isn't supported), then create the shadow dom manually
  useEffect(() => {
    const shadowHost = shadowHostRef.current;

    if (shadowHost && !shadowHost.shadowRoot) {
      shadowHost.attachShadow({ mode: 'open' });

      // Append the content of the shadow host to the shadow dom
      const item = document.createElement('div');
      item.innerHTML = shadowHost.children[0].innerHTML;
      shadowHost.shadowRoot!.appendChild(item.cloneNode(true));
    }
  }, []);

  // If the shadow dom is ready, initialise the fixes
  useEffect(() => {
    if (shadowHostRef.current) {
      const shadowHost = shadowHostRef.current as HTMLDivElement;

      if (shadowHost.shadowRoot) {
        // Register and initialise the plugins

        // General fixes
        WPPlugins.register(plugins.InvolveMePlugin());
        WPPlugins.register(plugins.JumpLinksPlugin());
        const iframeIds = ['skyscanner-calc', 'skyscanner-everywhere-agency'];
        WPPlugins.register(plugins.IframeResizePlugin(iframeIds));

        // Skyscanner Blocks
        WPPlugins.register(plugins.AccordionPlugin());
        WPPlugins.register(plugins.AudioPlayerPlugin());
        WPPlugins.register(plugins.FittyCardPlugin());
        WPPlugins.register(plugins.InspirationCardPlugin());
        WPPlugins.register(plugins.MapPlugin());
        WPPlugins.register(plugins.ShowcasesPlugin());
        WPPlugins.register(plugins.JetpackGalleryPlugin());
        WPPlugins.register(plugins.VideoPlugin());

        // Travel trends related
        WPPlugins.register(plugins.AosPlugin());
        WPPlugins.register(plugins.DatavisHbcPlugin());
        WPPlugins.register(plugins.DatavisPictogramPlugin());
        WPPlugins.register(plugins.DatavisPieChartPlugin());

        WPPlugins.init(shadowHost.shadowRoot);
      }
    }
  }, []);

  return (
    <root.div
      id="wordpress-shadow-host"
      ssr={ssr}
      ref={shadowHostRef}
      className={classNames(
        STYLES.WordPressShadowHost,
        isTravelTrends && STYLES['WordPressShadowHost--travel-trends'],
      )}
    >
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/themes/skyscanner-4.0/theme.af2a4932ccdaf36f8cf2.css"
      />
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/core/blocks/gallery/style.2d0e65202bc308cdf5ea.css"
      />
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/core/blocks/cover/style.555b3127d6ad79ca8d30.css"
      />
      <link
        rel="stylesheet"
        href="https://www.skyscanner.net/sttc/inspiration-image-builder/inspiration-image-builder-bundle-bee9778cf9e98816a9e1.min.css"
      />
      <div id="body" className={bodyClassNames}>
        <div className={contentClassNames}>
          <article className="page type-page status-publish has-post-thumbnail hentry">
            <div
              className={articleClassNames}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </article>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: bodyStyles,
        }}
      />
    </root.div>
  );
};

export default WordPressArticle;
