const GUTTER_WIDTH = 4;

/**
 * Distribute a difference across ns so that their sum matches the target
 *
 * @param   {Array<number>}  parts  - Array of numbers to fit
 * @param   {number}         target - Number that sum should match
 * @returns {Array<number>}         - Adjusted parts
 */
function adjustFit(parts: number[], target: number) {
  const diff = target - parts.reduce((sum, n) => sum + n, 0);
  const partialDiff = diff / parts.length;
  return parts.map((p) => p + partialDiff);
}

function handleRowResize(row: HTMLElement, width: number) {
  return applyRowRatio(row, getRowRatio(row), width);
}

function getRowRatio(row: HTMLElement): [number, number] {
  const result = getRowCols(row)
    .map(getColumnRatio)
    .reduce(
      ([ratioA, weightedRatioA], [ratioB, weightedRatioB]) => [
        ratioA + ratioB,
        weightedRatioA + weightedRatioB,
      ],
      [0, 0],
    );

  return [result[0], result[1]];
}

function getRowCols(row: HTMLElement) {
  return Array.from(row.querySelectorAll<HTMLElement>('.tiled-gallery__col'));
}

function getColImgs(col: HTMLElement) {
  return Array.from<HTMLElement>(
    col.querySelectorAll(
      '.tiled-gallery__item > img, .tiled-gallery__item > a > img',
    ),
  );
}

function getColumnRatio(col: HTMLElement) {
  const imgs = getColImgs(col);
  const imgCount = imgs.length;
  const ratio =
    1 /
    imgs
      .map((img) => getImageRatio(img as HTMLImageElement))
      .reduce((partialColRatio, imgRatio) => partialColRatio + 1 / imgRatio, 0);
  const result = [ratio, ratio * imgCount || 1];
  return result;
}

function getImageRatio(img: HTMLImageElement) {
  const w = parseInt(img.dataset.width || '0', 10);
  const h = parseInt(img.dataset.height || '0', 10);
  const result = w && !Number.isNaN(w) && h && !Number.isNaN(h) ? w / h : 1;
  return result;
}

function applyRowRatio(
  row: HTMLElement,
  [ratio, weightedRatio]: [number, number],
  width: number,
) {
  const rawHeight =
    (1 / ratio) *
    (width - GUTTER_WIDTH * (row.childElementCount - 1) - weightedRatio);

  return applyColRatio(row, {
    rawHeight,
    rowWidth: width - GUTTER_WIDTH * (row.childElementCount - 1),
  });
}

function applyColRatio(
  row: HTMLElement,
  { rawHeight, rowWidth }: { rawHeight: number; rowWidth: number },
) {
  const cols = getRowCols(row);

  const colWidths = cols.map(
    (col) =>
      (rawHeight - GUTTER_WIDTH * (col.childElementCount - 1)) *
      getColumnRatio(col)[0],
  );

  const adjustedWidths = adjustFit(colWidths, rowWidth);

  cols.forEach((col, i) => {
    const rawWidth = colWidths[i];
    const width = adjustedWidths[i];
    applyImgRatio(col, {
      colHeight: rawHeight - GUTTER_WIDTH * (col.childElementCount - 1),
      width,
      rawWidth,
    });
  });

  const colWidthPercentages = adjustedWidths.map((adjustedWidth) =>
    parseFloat(String((adjustedWidth / rowWidth) * 100)).toFixed(5),
  );

  return colWidthPercentages;
}

function applyImgRatio(
  col: HTMLElement,
  {
    colHeight,
    rawWidth,
    width,
  }: { colHeight: number; width: number; rawWidth: number },
) {
  const imgHeights = getColImgs(col).map(
    (img) => rawWidth / getImageRatio(img as HTMLImageElement),
  );
  const adjustedHeights = adjustFit(imgHeights, colHeight);

  // Set size of col children, not the <img /> element
  Array.from(col.children).forEach((item, i) => {
    const height = adjustedHeights[i];
    item.setAttribute('style', `height:${height}px;width:${width}px;`);
  });
}

export default handleRowResize;
