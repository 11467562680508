import { type WPPluginFunction } from '../../WPPlugins';

import handleRowResize from './helpers/handleRowResize';

const getGalleries = (root: ShadowRoot) =>
  Array.from(
    root.querySelectorAll<HTMLDivElement>(
      '.wp-block-jetpack-tiled-gallery.is-style-rectangular > .tiled-gallery__gallery,' +
        '.wp-block-jetpack-tiled-gallery.is-style-columns > .tiled-gallery__gallery',
    ),
  );

const handleObservedResize: () => ResizeObserverCallback = () => {
  let pendingRaf: number | null = null;

  return (entries) => {
    if (pendingRaf) {
      cancelAnimationFrame(pendingRaf);
    }

    pendingRaf = requestAnimationFrame(() => {
      pendingRaf = null;

      for (const entry of entries) {
        const { width: galleryWidth } = entry.contentRect;
        // We can't use childNodes because post content may contain unexpected text nodes
        const rows = Array.from(
          entry.target.querySelectorAll<HTMLDivElement>('.tiled-gallery__row'),
        );
        rows.forEach((row) => handleRowResize(row, galleryWidth));
      }
    });
  };
};

/**
 * Jetpack Gallery Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const JetpackGalleryPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const galleries = getGalleries(root);

    if (galleries.length === 0 || !ResizeObserver) {
      return;
    }

    const observer = new ResizeObserver(handleObservedResize);

    galleries.forEach((gallery) => {
      if (gallery.getAttribute('data-jetpack-block-initialized') === 'true') {
        return;
      }

      observer.observe(gallery);
      gallery.setAttribute('data-jetpack-block-initialized', 'true');
    });
  },
});

export default JetpackGalleryPlugin;
