import { GLOBAL } from 'saddlebag-browser';

import type { WPPluginFunction } from '../WPPlugins';

const loadCard = (element: HTMLScriptElement) => {
  // Make sure that we are passing in an appropriate element.
  if (!element) {
    return;
  }

  const $window = GLOBAL.getWindow();

  /**
   * Fills in the inspiration blocks appropriately. This function comes from a
   * Skyscanner-served JS file that loads before ours.
   */
  $window.inspirationImageBuilder(
    element,
    element.getAttribute('image-source'),
    element.getAttribute('image-width') || '100%',
    element.getAttribute('image-height') || '100%',
    element.getAttribute('image-title-city'),
    element.getAttribute('image-title-country'),
    element.getAttribute('image-subtitle'),
    element.getAttribute('image-call-to-action-url'),
  );
};

/**
 * Inspiration Card Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const InspirationCardPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    return [...root.querySelectorAll('script')]
      .filter(
        (node) =>
          node.hasAttribute('image-source') &&
          node.hasAttribute('image-call-to-action-url'),
      )
      .forEach((scriptTag) => loadCard(scriptTag));
  },
});

export default InspirationCardPlugin;
