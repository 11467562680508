import { type WPPluginFunction } from '../../WPPlugins';

import dataVisScrollListener from './helpers/datavisScrollListener';

/**
 * Initialises the DataVisScrollListener utility on the Pictogram data visualisation.
 * @returns {WPPlugin} - The WordPress Plugin
 */
const DatavisPictogramPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    dataVisScrollListener(root, {
      target: '.datavis-pictogram .datavis-pictogram__symbol',
      wrapper: '.datavis-pictogram',
    });
  },
});

export default DatavisPictogramPlugin;
