type WPPlugin = {
  init(root: ShadowRoot): Promise<void>;
};

export type WPPluginFunction<T> = (args: T) => WPPlugin;

class WPPlugins {
  static plugins: WPPlugin[] = [];

  static register(plugin: WPPlugin) {
    this.plugins.push(plugin);
  }

  static async init(root: ShadowRoot) {
    await Promise.allSettled(this.plugins.map((plugin) => plugin.init(root)));
  }
}

export default WPPlugins;
