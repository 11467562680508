import { GLOBAL } from 'saddlebag-browser';

let callback = () => {};

function containsAOSNode(nodes: HTMLCollectionOf<HTMLElement>) {
  let i;
  let currentNode;
  let result;

  for (i = 0; i < nodes.length; i += 1) {
    currentNode = nodes[i];

    if (currentNode.dataset && currentNode.dataset.aos) {
      return true;
    }

    result =
      currentNode.children &&
      containsAOSNode(currentNode.children as HTMLCollectionOf<HTMLElement>);

    if (result) {
      return true;
    }
  }

  return false;
}

const check: MutationCallback = (mutations) => {
  if (!mutations) return;

  // eslint-disable-next-line consistent-return
  mutations.forEach((mutation) => {
    const addedNodes = Array.prototype.slice.call(mutation.addedNodes);
    const removedNodes = Array.prototype.slice.call(mutation.removedNodes);
    const allNodes = addedNodes.concat(removedNodes);

    if (containsAOSNode(allNodes as unknown as HTMLCollectionOf<HTMLElement>)) {
      return callback();
    }
  });
};

function getMutationObserver() {
  const $window = GLOBAL.getWindow();

  return (
    $window.MutationObserver ||
    $window.WebKitMutationObserver ||
    $window.MozMutationObserver
  );
}

function isSupported() {
  return !!getMutationObserver();
}

function ready(selector: string, fn: () => void) {
  const $document = GLOBAL.getDocument();

  const MutationObserver = getMutationObserver();

  const observer = new MutationObserver(check);
  callback = fn;

  observer.observe($document.documentElement, {
    childList: true,
    subtree: true,
  });
}

export default { isSupported, ready };
