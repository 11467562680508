import { Loader } from '@googlemaps/js-api-loader';
import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../WPPlugins';

const loadMap = (
  el: HTMLDivElement,
  maps: any,
  markers: any,
  isMobile: boolean,
) => {
  const $document = GLOBAL.getDocument();
  const mapEl = el.querySelector('.skyscanner-map__container');

  if (!mapEl) {
    return;
  }

  const { center } = el.dataset;

  if (!center) {
    return;
  }

  const [lat, lng] = center.split(',').map((v) => parseFloat(v));
  const zoom = parseFloat(el.dataset.zoom || '8');
  const mobileZoom = parseFloat(el.dataset.mobileZoom || '6');

  const mapArgs = {
    mapId: '4c4729d99b94ff94',
    center: {
      lat,
      lng,
    },
    zoom: !isMobile ? zoom : mobileZoom,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    clickableIcons: false,
  };

  const mapInstance = new maps.Map(mapEl, mapArgs);

  const locationListItems = el.querySelectorAll<HTMLDivElement>(
    '.skyscanner-map__pins .skyscanner-map-marker',
  );

  locationListItems.forEach((locationListItemElement) => {
    const pinElement = $document.createElement('div');
    pinElement.classList.add('skyscanner-map-pin');

    if (locationListItemElement.dataset.category) {
      pinElement.classList.add(
        `skyscanner-map-pin--${locationListItemElement.dataset.category}`,
      );
    }

    const { position } = locationListItemElement.dataset;

    if (!position) {
      return;
    }

    const [pinLat, pinLng] = position.split(',').map((v) => parseFloat(v));

    const marker = new markers.AdvancedMarkerElement({
      position: {
        lat: pinLat,
        lng: pinLng,
      },
      title:
        locationListItemElement.querySelector('.skyscanner-map-marker__title')
          ?.textContent || '',
      map: mapInstance,
      content: pinElement,
    });

    const locationListPinElement = $document.createElement('button');
    locationListPinElement.setAttribute('type', 'button');
    locationListPinElement.classList.add('skyscanner-map-marker__icon');
    locationListItemElement.appendChild(locationListPinElement);

    locationListPinElement.addEventListener('click', () => {
      mapInstance.panTo({
        lat: pinLat,
        lng: pinLng,
      });
      mapInstance.setZoom(zoom);
    });

    marker.addListener('click', () => {
      locationListItemElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    });
  });

  /**
   * Elements that are not markers, should be displayed before the map on mobile.
   */
  if (isMobile) {
    const nonMarkerLocations = el.querySelectorAll(
      '.skyscanner-map__pins > *:not(.skyscanner-map-marker)',
    );
    nonMarkerLocations.forEach((nodeToMove) => {
      // Not a security issue, just moving an element within the page.
      el.prepend(nodeToMove);
      nodeToMove.classList.add('alignfull');
    });
  }
};

/**
 * Map Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const MapPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const $window = GLOBAL.getWindow();

    const elements = root.querySelectorAll<HTMLDivElement>('.skyscanner-map');
    const isMobile = $window.matchMedia('(max-width: 80rem)').matches;

    if (!elements) {
      return;
    }

    const GOOGLE_API_KEY = $window.skyscannerMapBlockData?.googleApiKey ?? '';

    if (!GOOGLE_API_KEY) {
      return;
    }

    const loader = new Loader({
      apiKey: GOOGLE_API_KEY,
      version: 'weekly',
      region: 'GB',
    });

    const [maps, markers] = await Promise.all([
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
    ]);

    elements.forEach((el) => loadMap(el, maps, markers, isMobile));
  },
});

export default MapPlugin;
