import { type WPPluginFunction } from '../WPPlugins';

const jumpToTarget = (e: MouseEvent, root: ShadowRoot) => {
  const target = e.target as HTMLAnchorElement;
  const targetIsJumpLink = target.matches('a[href^="#"]');

  if (targetIsJumpLink) {
    // Prevent the default behaviour
    e.preventDefault();

    // Scroll to the target element
    const headingId = target.getAttribute('href')!;
    root
      .getElementById(headingId.slice(1))
      ?.scrollIntoView({ behavior: 'smooth' });
  }
};

/**
 * Recreates anchor/jump link behaviour in shadow dom
 * @returns {WPPlugin} - The WordPress Plugin
 */
const JumpLinksPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    root.addEventListener<any>('click', (e) => jumpToTarget(e, root));
  },
});

export default JumpLinksPlugin;
