import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../WPPlugins';

/**
 * Video Plugin
 * @returns {WPPlugin} - The WordPress Plugin
 */
const Video: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const $document = GLOBAL.getDocument();

    root.querySelectorAll('.wp-block-video').forEach((videoBlockElement) => {
      const video = videoBlockElement.querySelector('video');

      if (!video) {
        return;
      }

      const buttonElement = $document.createElement('button');
      buttonElement.appendChild($document.createTextNode('Play'));
      buttonElement.classList.add('wp-block-video__play-button');
      videoBlockElement.appendChild(buttonElement);

      video.removeAttribute('controls');

      buttonElement.addEventListener('click', () => {
        buttonElement.remove();
        video.setAttribute('controls', 'controls');
        video.play();
      });
    });
  },
});

export default Video;
