import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../WPPlugins';

/**
 * Uses a slot to move the embed element outside of the Shadow DOM so it can be targeted globally.
 * More about slots: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/slot
 * @returns {WPPlugin} - The WordPress Plugin
 */
const InvolveMePlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    const embedName = 'involveme_embed';
    const embedElement = root.querySelector(`.${embedName}`);

    if (!embedElement) return;

    const $document = GLOBAL.getDocument();

    // Create the slot host which will contain the embed element
    const slotHost = $document.createElement('span');
    slotHost.slot = embedName;

    //  Create the slot content by cloning from original embed element
    const clonedElem = embedElement.cloneNode(true) as HTMLDivElement;
    slotHost.appendChild(clonedElem);

    // Recreate any scripts so they download and execute
    const scripts = clonedElem.querySelectorAll('script');

    scripts.forEach((script) => {
      // Create a new script element
      const newScript = $document.createElement('script');

      // Copy attributes from the original script to the new script
      for (const attr of script.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }

      // Replace the original script with the new script
      script.parentNode?.replaceChild(newScript, script);
    });

    // Add slot host to the web component (outside the Shadow DOM)
    const shadowHost = root.host;
    shadowHost.appendChild(slotHost);

    // Add a slot to the Shadow DOM
    const slot = $document.createElement('slot');
    slot.name = embedName;
    embedElement.replaceWith(slot);
  },
});

export default InvolveMePlugin;
