import { type WPPluginFunction } from '../../WPPlugins';

import dataVisScrollListener from './helpers/datavisScrollListener';

/**
 * Initialises the DataVisScrollListener utility on the Pie Chart data visualisation.
 * @returns {WPPlugin} - The WordPress Plugin
 */
const DatavisPieChartPlugin: WPPluginFunction<void> = () => ({
  async init(root: ShadowRoot) {
    dataVisScrollListener(root, {
      target: '.datavis-pie-chart .datavis-pie-chart__pie',
      wrapper: '.datavis-pie-chart',
      threshold: 1,
    });
  },
});

export default DatavisPieChartPlugin;
