import { GLOBAL } from 'saddlebag-browser';

import { type WPPluginFunction } from '../WPPlugins';

/**
 * Initialises the iFrameResize utility on embedded widgets.
 * @param {string[]} iframeIds - The IDs of the iframes to resize
 * @returns {WPPlugin} - The WordPress Plugin
 */
const IframeResizePlugin: WPPluginFunction<string[]> = (iframeIds) => ({
  async init(root: ShadowRoot) {
    const $window = GLOBAL.getWindow();

    if (typeof $window.iFrameResize !== 'function') {
      return;
    }

    iframeIds.forEach((iframeId) => {
      const iframe = root.querySelector(`#${iframeId}`);

      if (iframe) {
        // @ts-ignore
        $window.iFrameResize({ log: false }, iframe);
      }
    });
  },
});

export default IframeResizePlugin;
